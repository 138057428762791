/*
 *
 *   INSPINIA - Responsive Admin Theme
 *   version 2.6.2
 *
 */

$(document).ready(function () {


    // Add body-small class if window less than 768px
    if ($(this).width() < 769) {
        $('body').addClass('body-small')
    } else {
        $('body').removeClass('body-small')
    }

    // MetsiMenu
    $('#side-menu').metisMenu();

    // Collapse ibox function
    $('.collapse-link').on('click', function () {
        var ibox = $(this).closest('div.ibox');
        var button = $(this).find('i');
        var content = ibox.find('div.ibox-content');
        content.slideToggle(200);
        button.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
        ibox.toggleClass('').toggleClass('border-bottom');
        setTimeout(function () {
            ibox.resize();
            ibox.find('[id^=map-]').resize();
        }, 50);
    });

    // Close ibox function
    $('.close-link').on('click', function () {
        var content = $(this).closest('div.ibox');
        content.remove();
    });

    // Fullscreen ibox function
    $('.fullscreen-link').on('click', function () {
        var ibox = $(this).closest('div.ibox');
        var button = $(this).find('i');
        $('body').toggleClass('fullscreen-ibox-mode');
        button.toggleClass('fa-expand').toggleClass('fa-compress');
        ibox.toggleClass('fullscreen');
        setTimeout(function () {
            $(window).trigger('resize');
        }, 100);
    });

    // Minimalize menu
    $('.navbar-minimalize').on('click', function () {
        $("body").toggleClass("mini-navbar");
        SmoothlyMenu();

    });

    // Full height of sidebar
    function fix_height() {
        var heightWithoutNavbar = $("body > #wrapper").height() - 61;
        $(".sidebard-panel").css("min-height", heightWithoutNavbar + "px");

        var navbarHeigh = $('nav.navbar-default').height();
        var wrapperHeigh = $('#page-wrapper').height();

        if (navbarHeigh > wrapperHeigh) {
            $('#page-wrapper').css("min-height", navbarHeigh + "px");
        }

        if (navbarHeigh < wrapperHeigh) {
            $('#page-wrapper').css("min-height", $(window).height() + "px");
        }

        if ($('body').hasClass('fixed-nav')) {
            if (navbarHeigh > wrapperHeigh) {
                $('#page-wrapper').css("min-height", navbarHeigh + "px");
            } else {
                $('#page-wrapper').css("min-height", $(window).height() - 60 + "px");
            }
        }

    }

    fix_height();

    // Fixed Sidebar
    $(window).bind("load", function () {
        if ($("body").hasClass('fixed-sidebar')) {
            $('.sidebar-collapse').slimScroll({
                height: '100%',
                railOpacity: 0.9
            });
        }
    });

    $(window).bind("load resize scroll", function () {
        if (!$("body").hasClass('body-small')) {
            fix_height();
        }
    });

    // Add slimscroll to element
    $('.full-height-scroll').slimscroll({
        height: '100%'
    })

    $('#feedback').feedBackBox();
});


// Minimalize menu when screen is less than 768px
$(window).bind("resize", function () {
    if ($(this).width() < 769) {
        $('body').addClass('body-small')
    } else {
        $('body').removeClass('body-small')
    }
});

function SmoothlyMenu() {
    if (!$('body').hasClass('mini-navbar') || $('body').hasClass('body-small')) {
        // Hide menu in order to smoothly turn on when maximize menu
        $('#side-menu').hide();
        // For smoothly turn on menu
        setTimeout(
            function () {
                $('#side-menu').fadeIn(400);
            }, 200);
    } else if ($('body').hasClass('fixed-sidebar')) {
        $('#side-menu').hide();
        setTimeout(
            function () {
                $('#side-menu').fadeIn(400);
            }, 100);
    } else {
        // Remove all inline style from jquery fadeIn function to reset menu state
        $('#side-menu').removeAttr('style');
    }
}

/***** CUSTOM SCRIPT *****/

$(document).ajaxStart(function() {
    //$('.cssload-loading').show();
});
$(document).ajaxSuccess(function() {
    $('.cssload-loading').hide();
});
$(document).ajaxError(function() {
    $('.cssload-loading').hide();
});

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

/* Social network */
/*
$(function() {
    $.contextMenu({
        selector: '.network-item',
        items: {
            "message": {
                name: "Send message",
                icon: "fa-envelope-o"
            },
            "edit": {
                name: "Edit",
                icon: "fa-pencil-square-o"
            },
            "sep1": "---------",
            "close": {
                name: "Close",
                icon: "fa-times",
                callback: function(){
                    return true;
                }
            }
        }
    });
});
*/

$(document).ready(function() {

    loadImages();
    $('#search-top').keyup(function(key) {
        var text = $(this).val();
        if (text.length > 2) {
            if (key.key == 'Enter') {
                if ($('#search-autocomplete li.selected').length) {
                    var href = $('#search-autocomplete li.selected a').attr('href');
                } else {
                    var href = encodeURI('/search?q=' + $('#search-top').val());
                }
                window.location.href = href;
            } else if (key.key == 'ArrowDown' || key.key == 'ArrowUp') {
                if ($('#search-autocomplete li.selected').length) {
                    var i = $('#search-autocomplete').find('.selected').index();
                    $('#search-autocomplete li').removeClass('selected');
                    if (key.key == 'ArrowDown') {
                        i++;
                    } else {
                        i--;
                    }
                    $('#search-autocomplete li:eq(' + i + ')').addClass('selected');
                } else {
                    $('#search-autocomplete li').first().addClass('selected');
                }
            } else if (key.key == 'Escape') {
                $(this).val('');
                $('#search-autocomplete').html('');
            } else {
                $.ajax({
                    url: '/fast/?module=autocomplete&q=' + text,
                    headers: {'X-Api-Token': window.Laravel.apiToken},
                    success: function (data) {
                        $('#search-autocomplete').html(data);
                    }
                });
            }
            //console.log(key.key);
        } else {
            $('#search-autocomplete').html('');
        }
    });
    $('#search-top').keydown(function(key) {
        var text = $(this).val();
    });
    $('#search-top').focusout(function(obj) {
        if (!$(obj.relatedTarget).hasClass('clickable')) {
            $('#search-autocomplete').html('');
        }
    });

    $(document).on('submit', 'form.ajaxform', function (){
        $('.has-error', this).removeClass('has-error');
        $('.with-errors', this).remove();
        var form = this;
        $.ajax({
            type: 'POST',
            data: $(this).serialize(),
            error: function (data) {
                var errors = data.responseJSON.errors;
                //console.log(data);
                if (errors) {
                    $.each(errors,function(key, data) {
                        $(form).find('#'+key).parent().addClass('has-error').append('<div class="help-block with-errors">'+data+'</div>');
                    });
                }
            },
            success: function (data) {
                $('input[type]', form).val('');
                if (data.message) {
                    toastr[data.message.type](data.message.text);
                }
            }
        });
        return false;
    });

    $('#network-box a').click(function() {
        var name = $(this).text().trim();
        var slug = $(this).attr('href').substr($(this).attr('href').indexOf('#')+1);
        loadMessage(name, slug);
        return false;
    });

    $('#message-compose form').submit(function() {
        var myInput = '#message-compose form textarea.candisabled';
        if ($(myInput).val()) {
            $.ajax({
                type: 'POST',
                url: '/profile/me/messenger/send',
                data: $(this).serialize(),
                beforeSend: function () {
                    $(myInput).prop('disabled', true);
                },
                error: function () {
                    $.notify({
                        // options
                        message: 'Send of message failed!'
                    }, {
                        // settings
                        type: 'danger'
                    });
                    $(myInput).val('');
                    $(myInput).prop('disabled', false);
                },
                success: function (data) {
                    window.scrollTo(0, 0);
                    $(myInput).val('');
                    $(myInput).prop('disabled', false);
                    $('#messages-dock').prepend(data);
                    loadImages();
                }
            });
        }
        return false;
    });

    $(document).on('mouseover', '.message-box .caption', function() {
        $('.trash', this).show();
    });
    $(document).on('mouseout', '.message-box .caption', function() {
        $('.trash', this).hide();
    });
    $(document).on('click', '.message-box .trash', function() {
        $('#modal-remove').attr('data-target', '/profile/me/messenger/delete');
        $('#modal-remove').attr('data-id', $(this).attr('data-id'));
        $('#modal-remove .modal-body b').text($(this).attr('data-content'));
        $('#modal-remove').modal('show');
        return false;
    });
    $('#modal-remove-ok').click(function () {
        var id = $('#modal-remove').attr('data-id');
        $.ajax({
            type: 'POST',
            url: $('#modal-remove').attr('data-target'),
            data: {
                id: id
            },
            error: function() {
                $('#modal-remove .modal-info').html('<p class="text-warning">An error has occurred</p>');
            },
            success: function(data) {
                $('#modal-remove').modal('hide');
                if (data.deleted) {
                    $('#i' + id).remove();
                }
            }
        });
    });
    $('#modal-remove').on('hidden.bs.modal', function () {
        $('#modal-remove .modal-body b').text('');
        $('#modal-remove .modal-info').html('');
    });

});

function loadMessage(name, slug) {
    if ($('#p-' + slug).length) {
        $.ajax({
            type: 'GET',
            url: '/profile/me/messenger/load',
            data: {
                slug: slug
            },
            beforeSend: function () {
                showLoader();
                $('#messages-dock').html('');
                $('#message-compose').hide();
            },
            error: function () {
            },
            success: function (data) {
                $('#messenger h2').first().text('Conversation with ' + name);
                $('#messages-dock').html(data);
                loadImages('message-box');
                $('#message-compose').show();
                $('#message-slug').val(slug);
            }
        });
    }
}

/* Edit biography without account */

$('.text-edit').hover(function() {
    $(this).addClass('hover');
}, function () {
    $(this).removeClass('hover');
});

$('.text-edit').click(function () {
    if ($(this).parent('.caption').hasClass('loggedin')) {
        $('#modal-edit').attr('data-target', '/profile/me/save');
    } else {
        $('#modal-edit').attr('data-target', '/api/correction?h=' + $(this).attr('data-hash'));
    }
    $('#modal-edit .modal-title').attr('data-item', ($('b', this).attr('data-id')));
    $('#modal-edit .modal-title').text($('b', this).attr('data-title'));
    $('#modal-edit .modal-body textarea').attr('data-current', $('span', this).text());
    $('#modal-edit .modal-body textarea').val($('span', this).text());
    $('#modal-edit .modal-info').text(null);
    $('#modal-edit').modal('show');
});

$('#modal-edit-save').click(function () {
    $.ajax({
        type: 'POST',
        url: $('#modal-edit').attr('data-target'),
        data: {
            item: $('#modal-edit .modal-title').attr('data-item'),
            current: $('#modal-edit .modal-body textarea').attr('data-current'),
            new: $('#modal-edit .modal-body textarea').val()
        },
        error: function() {
            $('#modal-edit .modal-info').html('<p class="text-warning">An error has occurred</p>');
        },
        success: function(data) {
            handleBioPreviewButtonClass(data);
            $('#corrections').html(data);
            $('#modal-edit').modal('hide');
        }
    });
});

$(document).on('click', '.correction-edit', function () {
    $('#modal-edit .modal-info').html('');
    var id = $(this).attr('data-item');
    $('#' + id).click();
});

$(document).on('click', '.correction-delete', function () {
    $('#modal-delete .modal-info').html('');
    if ($(this).closest('.caption').hasClass('loggedin')) {
        $('#modal-delete').attr('data-target', '/profile/me/correction/delete');
    } else {
        $('#modal-delete').attr('data-target', '/api/correction/delete?h=' + $(this).attr('data-hash'));
    }
    $('#modal-delete').attr('data-id', $(this).attr('data-id'));
    $('#modal-delete .modal-body b').text($(this).attr('data-content'));
    $('#modal-delete').modal('show');
});

$('#modal-delete-delete').click(function () {
    $.ajax({
        type: 'POST',
        url: $('#modal-delete').attr('data-target'),
        data: {
            id: $('#modal-delete').attr('data-id')
        },
        error: function() {
            $('#modal-delete .modal-info').html('<p class="text-warning">An error has occurred</p>');
        },
        success: function(data) {
            handleBioPreviewButtonClass(data);
            $('#corrections').html(data);
            $('#modal-delete').modal('hide');
        }
    });
});

$('#bio-preview-btn').click(function () {
    if ($(this).hasClass('loggedin')) {
        var url = '/profile/me/correction/preview';
    } else {
        var url = '/api/preview?h=' + $(this).attr('data-hash');
    }
    $.get(url, function(data) {
        $('#modal-info .modal-title').text('Preview of corrected biography');
        $('#modal-info .modal-body').html(data);
    });
    $('#modal-info').modal('show');
});

function handleBioPreviewButtonClass(data) {
    if (data.length > 50) {
        $('#bio-preview-btn').removeClass('hidden');
    } else {
        $('#bio-preview-btn').addClass('hidden');
    }
}

/* oxxi */

$(function(){
    jQuery('#float-box').stickyfloat({
        delay:    50,
        duration: 100,
        easing:   'swing'
    });
});

function loadImages(className) {
    if (className == undefined) {
        classNameIns = '';
    } else {
        classNameIns = '.' + className + ' ';
    }
    $(classNameIns + 'img').each(function() {
        if ($(this).attr('data-src')) {
            $(this).attr('src', $(this).attr('data-src'));
            $(this).load(function() {
                $(this).show();
            });
        }
    });
}

function showLoader() {
    $('.cssload-loading').show();
}